<template>
	<div class="material">
		<div class="material_screen">
			<div class="mian">
				<div class="inner_nav clearfix">
					<div class="fl inner_nav_l">当前位置：字体素材</div>
					<div class="fr inner_nav_r">
						共 <span>{{ total }}</span> 个作品
					</div>
				</div>
				<div class="inner_banner" @click="jumpBannerFun(banner.url, banner)">
					<img :src="banner.thumb" alt="" />
				</div>
				<div class="material_search">
					<!-- :placeholder="history"  隐藏的搜索历史 -->
					<input type="text" v-model="keywords" @keyup.enter="searchFun" />
					<!-- onkeyup="this.value=this.value.replace(/[ ]/g,'')" -->
					<div class="material_search_button" @click="searchFun">
						<img src="../assets/images/indexsearch.png" alt="" />
					</div>
				</div>
				<!-- 分类 -->
				<div class="material_class clearfix">
					<div class="fl" v-for="(item, index) in themeList" :key="index"
						:class="navtabIndex == index ? 'active' : ''" @click="navtabFun(index, item.id)">
						{{ item.title }}<span></span>
					</div>
				</div>
				<div class="material_class_list" :class="unfold ? '' : 'min'">
					<div class="material_class_item" v-for="(item, index) in navList" :key="index">
						<div class="class_list_title">{{ item.name }}：</div>
						<div class="class_list clearfix" :class="!item.isShow ? 'mini' : ''"
							:ref="'class_list' + index">
							<div v-for="(items, indexs) in item.cate_list" :key="indexs" :ref="'getTitleRefs' + index"
								class="fl" :class="item.index == indexs ? 'active' : ''"
								@click="cateFun(index, indexs, items)">
								{{ items.name }}
							</div>
						</div>
						<div v-if="item.unfold" class="class_list_unfold" @click="item.isShow = !item.isShow">
							{{ !item.isShow ? "展开" : "收起"
              }}<img src="../assets/images/unfold.png" alt="" />
						</div>
					</div>
				</div>

				<div class="sort clearfix">
					<div class="fl sort_l clearfix">
						<div class="fl">排列方式：</div>
						<div class="fl sort_l_box" @mouseenter="sortShow = true" @mouseleave="sortShow = false">
							<div class="sort_l_s">
								{{ sortList[sortIndex].name
                }}<img src="../assets/images/sanjiao.png" alt="" />
							</div>
							<div class="sort_l_ul" v-show="sortShow">
								<div v-for="item in sortList" :key="item.id" @click="sortFun(item.id)">
									{{ item.name }}
								</div>
							</div>
						</div>
					</div>
					<div class="fr sort_r" :class="unfold ? 'min' : ''" @click="unfold = !unfold">
						{{ unfold ? "收起" : "更多" }}筛选
						<img src="../assets/images/shaixuan.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="mian">
			<div class="list">
				<div class="daily clearfix">
					<template v-if="list.length > 0">
						<!-- wow animate__animated animate__fadeInUp -->
						<div class="daily_ul" v-for="(row, i) in list" :key="i">
							<div class="daily_li" :class="{ visibles: isVisibility }"
								:style="{ animationDelay: 0.1 * index + 's' }" v-for="(item, index) in row" :key="index"
								@click="toDetail(item.id)">
								<div class="daily_li_box">
									<img v-lazy="item.thumb" :key="item.thumb" alt="" />
									<div class="daily_li_mask"></div>
									<div class="daily_li_downLoad" @click.stop="downFun(item.id)">
										下载
										<!-- <span></span>
                                        <img src="../assets/images/icon_mrtj_download@2x.png" alt=""> -->
									</div>
									<div class="daily_li_collect" v-if="item.is_collect == 0"
										@click.stop="collectFun(2, item.id, index)">
										<img src="../assets/images/weishoucang.svg" alt="" />
									</div>
									<div class="daily_li_collect" v-else @click.stop="uncollectFun(2, item.id, index)">
										<img src="../assets/images/icon_collection@2x.svg" alt="" />
									</div>
									<div class="daily_li_shop" v-if="item.is_down_cart == 0"
										@click.stop="jionFun(item.id)">
										<img src="../assets/images/icon_add@2x.svg" alt="" />
									</div>
									<div class="daily_li_shop" v-else @click.stop="jionFun(item.id)">
										<img src="../assets/images/addqingdan1.svg" alt="" />
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
				<!-- v-if="total > 0" -->
				<div class="loading" v-if="list.length < 1">
					正在加载中
					<img src="../assets/images/jiazai.png" alt="" />
				</div>

				<el-empty v-if="total == 0 && emptyShow" description="暂无数据"></el-empty>
				<page v-if="total > 16" :page="page" :limit="limit" :total="total" ref="page"></page>
			</div>
			<!-- 推荐专题 -->
			<div>
				<div class="public_title">
					<div class="public_title_name">推荐专题</div>
					<router-link to="/project" class="public_title_more">查看更多</router-link>
				</div>
				<div class="project clearfix">
					<template v-if="projectList.length > 0">
						<!-- wow animate__animated animate__fadeInUp -->
						<div class="fl project_li" :style="{ animationDelay: 0.1 * index + 's' }"
							v-for="(item, index) in projectList" :key="index" @click="projectFun(item.id)">
							<div class="project_li_box">
								<img v-lazy="item.thumb" :key="item.thumb" alt="" />
								<div class="project_li_hot" v-if="item.is_hot == 1">HOT</div>
								<div class="project_li_buttom">{{ item.title }}</div>
								<div class="project_li_mask">
									<p>{{ item.title }}</p>
								</div>
								<div class="project_li_collect" v-if="item.is_collect == 0"
									@click.stop="collectFun(1, item.id, index)">
									<img src="../assets/images/weishoucang.svg" alt="" />
								</div>
								<div class="project_li_collect" v-else @click.stop="uncollectFun(1, item.id, index)">
									<img src="../assets/images/icon_collection@2x.svg" alt="" />
								</div>
							</div>
						</div>
					</template>
					<el-empty v-if="projectList.length < 1 && emptyShow" description="暂无数据"></el-empty>
				</div>
			</div>
			<!-- 视频教程 -->
			<div>
				<div class="public_title">
					<div class="public_title_l public_title_ls">
						<div class="public_title_name">推荐视频</div>
					</div>
					<router-link to="/video" class="public_title_more">查看更多</router-link>
				</div>
				<div class="video_list clearfix">
					<template v-if="videoList.length > 0">
						<!-- wow animate__animated animate__fadeInUp -->
						<div class="fl video_li" :style="{ animationDelay: 0.1 * index + 's' }"
							v-for="(item, index) in videoList" :key="index" @click="videoFun(item.id)">
							<div class="video_li_box">
								<div class="video_thumb">
									<img v-lazy="item.thumb" :key="item.thumb" alt="" />
								</div>
								<div class="project_li_buttom">{{ item.title }}</div>
								<div class="video_li_mask"></div>
								<div class="video_li_height">{{ item.level_name }}</div>
								<!-- <div class="daily_li_collect" v-if="item.is_collect == 0" @click.stop="collectFun(3,item.id,index)"><img src="../assets/images/weishoucang.svg" alt=""></div>
                                <div class="daily_li_collect" v-else @click.stop="uncollectFun(3,item.id,index)"><img src="../assets/images/icon_collection@2x.svg" alt=""></div> -->
								<div class="video_li_play">
									<img src="../assets/images/icon_play@2x.png" alt="" />
								</div>
							</div>
						</div>
					</template>
					<el-empty v-if="videoList.length < 1 && emptyShow" description="暂无数据"></el-empty>
				</div>
			</div>
		</div>
		<!-- 侧边导航 -->
		<sidebar ref="downtotal"></sidebar>
		<div class="mask" v-if="isShow" @click="closeMsg">
			<collection isMask="1" :collectionId="collId" :type="type" @closeFun="closeFun"></collection>
		</div>
	</div>
</template>

<script>
	import sidebar from "../components/sidebar.vue";
	import collection from "../components/collection.vue";
	import page from "../components/page.vue";

	export default {
		name: "material",
		components: {
			sidebar,
			collection,
			page,
		},
		data() {
			return {
				banner: {}, //banner

				material_total: 0, //作品总数
				keywords: "", //搜索内容
				unfold: true,
				themeList: [], //分类
				navtabIndex: 0, //选中分类
				navList: [{
						name: "节日",
						list: [],
					},
					{
						name: "行业",
						list: [],
					},
					{
						name: "风格",
						list: [],
					},
					{
						name: "节气",
						list: [],
					},
					{
						name: "用途",
						list: [],
					},
				],

				sortList: [{
						id: 0,
						name: "综合推荐",
					},
					{
						id: 1,
						name: "最新上传",
					},
					{
						id: 2,
						name: "浏览最多",
					},
					{
						id: 3,
						name: "下载最多",
					},
				],
				sortIndex: 0, //排序方式
				sortShow: false, //显示更多排序

				list: [],
				isVisibility: false,
				projectList: [],
				videoList: [],
				emptyShow: false,

				isShow: false, //收藏夹显示

				// 分页
				total: 0, //总数
				page: 1, //页数
				limit: 80, //每页个数

				// 收藏参数
				type: "", //类型
				collId: "", //收藏id

				// 接收参数
				navId: "",
				navIndex: 0,

				navName: "",

				indexHistory: [],
				history: "",

				scroll: 0,
				festival: 0,
				solar_terms: 0,
				industry: 0
			};
		},
		mounted() {
			if (localStorage.getItem("indexHistory")) {
				this.indexHistory = localStorage
					.getItem("indexHistory")
					.split(",")
					.slice(0, 8);
				this.history = this.indexHistory.join(" ");
			}
			this.$parent.routerIndex = 2;
			this.bannerOneban();
			if (this.$route.query.keywords) {
				this.keywords = this.$route.query.keywords;
			}
			if (this.$route.query.themeIndex) {
				this.navtabIndex = this.$route.query.themeIndex;
			}
			if (this.$route.query.id) {
				this.navId = this.$route.query.id;
			}
			if (this.$route.query.index) {
				this.navIndex = this.$route.query.index;
			}
			//console.log(111111111111111111);
			if (localStorage.getItem("material_name")) {
				this.navName = localStorage.getItem("material_name");
				localStorage.removeItem("material_name");
			}
			if (localStorage.getItem("festival") && localStorage.getItem("festival") != 0) {
				this.festival = localStorage.getItem("festival");
			}
			if (localStorage.getItem("solar_terms") && localStorage.getItem("solar_terms") != 0) {
				this.solar_terms = localStorage.getItem("solar_terms");
			}
			if (localStorage.getItem("industry") && localStorage.getItem("industry") != 0) {
				this.industry = localStorage.getItem("industry");
			}

			if (this.$store.state.navList) {
				this.themeList = this.$store.state.navList.theme_list;
				this.material_total = this.$store.state.navList.material_total;
				if (this.themeList && this.themeList.length > 0) {
					this.navtabFun(
						this.navtabIndex,
						this.themeList[this.navtabIndex == -1 ? 0 : this.navtabIndex].id
					);
				}
			}
		},
		methods: {
			// 获取内部banner、
			bannerOneban() {
				var that = this;
				this.$api.POST(
					this.$face.bannerOneban, {
						location: 2,
					},
					function(res) {
						switch (res.data.links) {
							case 0:
								res.data.url = "";
								break;
							case 1:
								res.data.url = "material";
								break;
							case 2:
								res.data.url = "video";
								break;
							case 3:
								res.data.url = "deformation";
								break;
							case 4:
								res.data.url = "ranking";
								break;
							case 5:
								res.data.url = "dailyWord";
								break;
							case 6:
								res.data.url = "fontSearch";
								break;
							case 7:
								res.data.url = "dailyWordUpload";
								break;
							case 8:
								res.data.url = "vip";
								break;
							case 9:
								res.data.url = "project";
								break;
							case 10:
								res.data.url = "rankingDetail";
								break;
							case 11:
								res.data.url = "projectDetail";
								break;
							case 12:
								res.data.url = "downLoad";
								break;
						}
						that.banner = res.data;
					}
				);
			},

			navtabFun(index, id) {
				this.navtabIndex = index;
				var that = this;
				var params = {
					theme_id: id,
					whole: 1,
				};
				this.$api.POST(this.$face.themeList, params, function(res) {
					res.data.other_list.forEach((element) => {
						console.log(that.navName);
						if (that.navId && that.navId == element.id) {
							element.index = that.navIndex;
							element.isShow = false;
							console.log(element.index);
						} else {
							element.index = 0;
							element.isShow = false;

							element.cate_list.forEach((item, index) => {
								if (
									(that.navName && that.navName == item.name) ||
									(that.festival != 0 && that.festival == item.id) ||
									(that.solar_terms != 0 && that.solar_terms == item.id) ||
									(that.industry != 0 && that.industry == item.id)
								) {
									element.index = index;
								}
							});

						}
					});
					that.navList = res.data.other_list;
					console.log(that.navList);
					that.page = 1;
					that.listFun();
					that.$nextTick(() => {
						that.countFun();
					});
				});
			},
			// 计算分类是否需要展示更多
			countFun() {
				this.navList.forEach((row, i) => {
					var wid = 0,
						boxw = 0;
					if (this.$refs["getTitleRefs" + i]) {
						this.$refs["getTitleRefs" + i].forEach((item) => {
							wid += item.clientWidth;
						});
					}
					if (this.$refs["class_list" + i]) {
						this.$refs["class_list" + i].forEach((item) => {
							boxw = item.clientWidth - 152;
						});
					}
					if (wid > boxw) {
						row.unfold = true;
					} else {
						row.unfold = false;
					}
				});
				console.log(this.navList);
			},
			cateFun(index, indexs, row) {
				// this.navName = row.name;
				if (this.navList[index].index == indexs) {
					this.navList[index].index = 0;
				} else {
					this.navList[index].index = indexs;
				}
				console.log(indexs, this.navList[index]);
				this.$forceUpdate;
				this.page = 1;
				this.listFun();
			},

			// 排序
			sortFun(e) {
				this.sortShow = false;
				this.sortIndex = e;
				this.page = 1;
				this.listFun();
			},

			//搜索
			searchFun() {
				if (!this.keywords) {
					this.keywords = "";
					this.navtabIndex = 0;
					this.navId = "";
					this.navIndex = 0;
					this.navList.forEach((element) => {
						element.index = 0;
						element.isShow = false;
					});
				}
				this.page = 1;
				this.list = [];
				this.listFun();
			},
			// 获取列表
			listFun() {
				if (this.keywords) {
					this.indexHistory.forEach((item, index) => {
						if (item == this.keywords) {
							this.indexHistory.splice(index, 1);
						}
					});
					this.indexHistory.unshift(this.keywords);
					this.history = this.indexHistory.join(" ");
					localStorage.setItem("indexHistory", this.indexHistory.join(","));
				}

				var style = 0,
					industry = 0,
					festival = 0,
					solar_terms = 0,
					purpose = 0,
					other = [];
				this.navList.forEach((item, index) => {
					if (item.id == 3) {
						style = item.cate_list[item.index].id;
					} else if (item.id == 2) {
						industry = item.cate_list[item.index].id;
					} else if (item.id == 1) {
						festival = item.cate_list[item.index].id;
					} else if (item.id == 4) {
						solar_terms = item.cate_list[item.index].id;
					} else if (item.id == 5) {
						purpose = item.cate_list[item.index].id;
					} else {
						other.push(item.cate_list[item.index].id);
					}
				});
				var that = this;
				var params = {
					theme: this.navtabIndex == -1 ? 0 : this.themeList[this.navtabIndex].id,
					style: style,
					industry: industry,
					festival: festival,
					solar_terms: solar_terms,
					purpose: purpose,
					other: other.join(","),
					order_state: this.sortIndex,
					page: this.page,
					limit: this.limit,
					// limit:8,
					_keywords: this.keywords,
				};
				if (this.$refs.page && this.page == 1) {
					this.page = 1
					this.$refs.page.currentPage = 1;
				}
				this.$api.POST(this.$face.materialList, params, function(res) {
					// that.list = res.data.material.lists;

					if (that.page == 1) {
						that.total = res.data.material.total;
					}
					that.projectList = res.data.special;
					that.videoList = res.data.video;
					that.emptyShow = true;
					that
						.$nextTick(() => {
							that.list = that.$utile.listData(res.data.material.lists);
						})
						.then(() => {
							that.isVisibility = true;
						});
				});
			},
			// 查看详情
			toDetail(id) {
				// localStorage.setItem("clearAll", 2);
				// this.$router.push({
				// 	name: 'materialDetail',
				// 	query: {
				// 		id: id
				// 	}
				// })
				let routeData = this.$router.resolve({
					name: "materialDetail",
					query: {
						id: id,
					},
				});
				window.open(routeData.href, "_blank");
			},

			//收藏列表
			collectFun(type, id, index) {
				if (localStorage.getItem("access_token")) {
					if (
						localStorage.getItem("access_token") == "" ||
						localStorage.getItem("access_token") == undefined ||
						localStorage.getItem("access_token") == null
					) {
						this.$parent.loginShow();
						return;
					}


					this.type = type;
					this.collId = id;
					this.isShow = true;
				} else {
					this.$parent.loginShow();
				}
			},
			//取消收藏
			uncollectFun(type, id, index) {
				var that = this;
				var params = {
					coll_id: id,
					type: type,
				};
				this.$api.POST(this.$face.collectionColl, params, function(res) {
					// that.videoList[index].is_collect = 0;
					that.$utile.prompt("success", "取消收藏");
					that.listFun();
				});
			},

			//关闭收藏
			closeFun(e) {
				this.isShow = false;
				if (e != 1) {
					this.listFun();
				}
			},

			closeMsg(ev) {
				let dom = document.getElementById("child");
				if (dom) {
					if (!dom.contains(ev.target)) {
						this.isShow = false;
					}
				}
			},

			// 下载源文件
			downFun(id) {
				if (
					localStorage.getItem("access_token") == "" ||
					localStorage.getItem("access_token") == undefined ||
					localStorage.getItem("access_token") == null
				) {
					this.$parent.loginShow();
					return;
				}


				var that = this;
				var params = {
					id: id,
				};
				this.$api.POST(this.$face.downloadDown, params, function(res) {
					const a = document.createElement("a");
					//   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
					a.href = res.data.down_url; // 完整的url则直接使用
					// document.body.appendChild(a)
					a.click();
					that.$utile.prompt("success", res.data.msg);
				});
			},

			//加入清单
			jionFun(id) {
				if (
					localStorage.getItem("access_token") == "" ||
					localStorage.getItem("access_token") == undefined ||
					localStorage.getItem("access_token") == null
				) {
					this.$parent.loginShow();
					return;
				}



				var that = this;
				var params = {
					material_id: id,
					type: 1,
				};
				this.$api.POST(this.$face.download_cartJoin, params, function(res) {
					that.$utile.prompt("success", res.msg);
					that.listFun();
					that.$refs.downtotal.listFun();
				});
			},

			//查看专题
			projectFun(id) {
				// let routeData = this.$router.resolve({
				//   name: "projectDetail",
				//   query: {
				//     id: id,
				//   },
				// });
				// window.open(routeData.href, "_blank");
				this.$router.push({
					name: "projectDetail",
					query: {
						id: id,
					},
				});
			},
			//查看视频
			videoFun(id) {
				//   this.$router.push({
				//     name: "videoDetail",
				//     query: {
				//       id: id,
				//     },
				//   });
				let routeData = this.$router.resolve({
					name: "videoDetail",
					query: {
						id: id,
					},
				});
				window.open(routeData.href, "_blank");
			},
			jumpBannerFun(url, param) {
				if (param.links == 0) {
					return;
				}
				if (param.links == 15) {
					window.open(param.urls);
					return;
				}
				if (param.festival != 0) {
					localStorage.setItem("festival", param.festival);
				}
				if (param.solar_terms != 0) {
					localStorage.setItem("solar_terms", param.solar_terms);
				}
				if (param.industry != 0) {
					localStorage.setItem("industry", param.industry);
				}

				if (param.font_cate != 0) {
					localStorage.setItem("font_cate", param.font_cate);
				}
				if (param.font_style != 0) {
					localStorage.setItem("font_style", param.font_style);
				}
				if (param.series != 0) {
					localStorage.setItem("series", param.series);
				}
				let routeData = this.$router.resolve({
					name: url
				});
				window.open(routeData.href, "_blank");

				this.bannerrecord(param.id)
			},
			// 点击banner统计次数接口
			bannerrecord(id) {
				console.log('bannerid', id)
				var that = this;
				var params = {
					banner_id: id,
				};
				this.$api.POST(this.$face.bannerecord, params, function(res) {
					console.log('bannerres', res)
				});
			},
			// 瀑布流
			caLFlex() {
				let arr1 = []; // 第一列的值
				let arr2 = []; // 第二列的值
				let arr3 = []; // 第二列的值
				let arr4 = []; // 第二列的值
				let heightArry_1 = []; // 第一列的卡片高度
				let heightArry_2 = []; // 第二列的卡片高度
				let heightArry_3 = []; // 第二列的卡片高度
				let heightArry_4 = []; // 第二列的卡片高度
				this.list.forEach((item, index) => {
					console.log(item.high);
					if (index === 0) {
						// 第一行中的元素无需判断，直接加到新的数组中
						heightArry_1.push(item.high);
						arr1.push(this.list[index]);
						return;
					}
					if (index === 1) {
						heightArry_2.push(item.high);
						arr2.push(this.list[index]);
						return;
					}
					if (index === 2) {
						heightArry_3.push(item.high);
						arr3.push(this.list[index]);
						return;
					}
					if (index === 3) {
						heightArry_4.push(item.high);
						arr4.push(this.list[index]);
						return;
					}
					console.log(
						heightArry_1.length,
						heightArry_2.length,
						heightArry_3.length,
						heightArry_4.length
					);
					const heightTotal_1 = heightArry_1.length ?
						Array.from(heightArry_1).reduce((acc, cur) => acc + cur) :
						0; // 第一列的总高度
					const heightTotal_2 = heightArry_2.length ?
						Array.from(heightArry_2).reduce((acc, cur) => acc + cur) :
						0; // 第二列的总高
					const heightTotal_3 = heightArry_3.length ?
						Array.from(heightArry_3).reduce((acc, cur) => acc + cur) :
						0; // 第三列的总高度
					const heightTotal_4 = heightArry_4.length ?
						Array.from(heightArry_4).reduce((acc, cur) => acc + cur) :
						0; // 第三列的总高度

					// 找到最小值
					let minNumber = Math.min(
						heightTotal_1,
						heightTotal_2,
						heightTotal_3,
						heightTotal_4
					);
					console.log(minNumber);
					switch (minNumber) {
						case heightTotal_1:
							heightArry_1.push(item.high);
							arr1.push(this.list[index]);
							break;
						case heightTotal_2:
							heightArry_2.push(item.high);
							arr2.push(this.list[index]);
							break;
						case heightTotal_3:
							heightArry_3.push(item.high);
							arr3.push(this.list[index]);
							break;
						case heightTotal_4:
							heightArry_4.push(item.high);
							arr4.push(this.list[index]);
							break;
					}
				});
				// 重新将数据赋值给各列数组
				this.list1 = arr1;
				this.list2 = arr2;
				this.list3 = arr3;
				this.list4 = arr4;
			},
		},
		watch: {
			"$store.state.navList"(newval) {
				this.themeList = newval.theme_list;
				this.material_total = newval.material_total;

				console.log('this.themeList', this.themeList)
				this.themeList.forEach((item, index) => {
					if (
						this.navName == item.title
					) {
						this.navtabIndex = index
					}
				})
				localStorage.removeItem("festival");
				localStorage.removeItem("solar_terms");
				localStorage.removeItem("industry");
				this.navtabFun(
					this.navtabIndex,
					this.themeList[this.navtabIndex == -1 ? 0 : this.navtabIndex].id
				);
			},
			keywords(newval) {
				newval = newval.replace(/[ ]/g, "");
				this.$nextTick(() => {
					this.keywords = newval;
				});
			},
		},
		// 路由跳转之前保存当前滚动条的位置。
		beforeRouteLeave(to, from, next) {
			console.log(from);
			this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
			next();
			// if (to.name == 'material' || to.name == 'video') { // 需要缓存的路由name
			// 	to.meta.keepAlive = false
			//     from.meta.keepAlive = true
			// 	next()
			// }else{
			// 	from.meta.keepAlive = true
			// 	next()
			// }
		},
		activated() {
			this.$nextTick(() => {
				this.$parent.routerIndex = 2;
				this.$parent.showIndex = 1;

				// 1 清空筛选
				// 2 返回保留
				// 3 关键字筛选
				if (localStorage.getItem("clearAll") == 1) {
					this.page = 1;
					if (this.$refs.page) {
						this.$refs.page.currentPage = 1;
					}
					this.keywords = "";
					this.navtabIndex = 0;
					this.navId = "";
					this.navIndex = 0;
					this.navtabFun(
						this.navtabIndex,
						this.themeList[this.navtabIndex == -1 ? 0 : this.navtabIndex].id
					);
					this.navList.forEach((element) => {
						element.index = 0;
						element.isShow = false;
					});
				} else if (localStorage.getItem("clearAll") == 2) {
					setTimeout(() => {
						document.documentElement.scrollTop = this.scroll;
					}, 0);
				} else if (localStorage.getItem("clearAll") == 3) {
					this.navList.forEach((element, i) => {
						if (localStorage.getItem("antString")) {
							this.keywords = "";
							element.index = 0;
							element.isShow = false;
							element.cate_list.forEach((item, index) => {
								if (localStorage.getItem("antString") == item.name) {
									element.index = index;
									if (this.$refs["getTitleRefs" + i]) {
										this.$refs["getTitleRefs" + i].forEach((row, s) => {
											if (s == index) {
												if (row.offsetTop > 10) {
													element.isShow = true;
												}
											}
										});
									}
								}
							});
						}
					});
				} else {
					if (this.$route.query.keywords) {
						this.keywords = this.$route.query.keywords;
					} else {
						this.keywords = "";
					}
					if (
						this.$route.query.themeIndex ||
						this.$route.query.themeIndex === 0
					) {
						this.navtabIndex = this.$route.query.themeIndex;
					} else {
						this.navtabIndex = 0;
					}
					if (this.$route.query.id) {
						this.navId = this.$route.query.id;
					}
					if (this.$route.query.index || this.$route.query.index === 0) {
						this.navIndex = this.$route.query.index;
					} else {
						this.navIndex = 0;
					}
					this.navtabFun(
						this.navtabIndex,
						this.themeList[this.navtabIndex == -1 ? 0 : this.navtabIndex].id
					);
					this.navList.forEach((element) => {
						element.index = 0;
						element.isShow = false;
						if (this.navId && this.navId == element.id) {
							element.index = this.navIndex;
							element.isShow = false;
						}
					});
				}
				this.$forceUpdate();
				localStorage.removeItem("antString");
				localStorage.removeItem("clearAll");
				this.listFun();
				this.$refs.downtotal.listFun();
			});
		},
	};
</script>

<style scoped>
	.el-empty {
		position: relative;
		z-index: 96;
		background-color: #f6f6f6;
	}

	.loading {
		width: 100%;
		height: 800px;
		text-align: center;
		font-size: 20px;
		color: #999999;
		text-align: center;
		position: absolute;
		top: 50px;
		left: 0;
		z-index: 1;
		padding-top: 100px;
		box-sizing: border-box;
	}

	.loading img {
		width: 24px;
		height: 24px;
		display: inline-block !important;
		vertical-align: middle;
		animation: fadeum 5s infinite;
	}

	@keyframes fadeum {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	.material_screen {
		width: 100%;
		background: #ffffff;
	}

	.inner_nav {
		padding: 27px 0 17px;
	}

	.inner_nav_l {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
	}

	.inner_nav_r {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
		/* background: #ECF1F4; */

		border-radius: 4px;
	}

	.inner_nav_r span {
		font-weight: bold;
		font-size: 16px;
		color: #5957ff;
	}

	.inner_banner {
		display: block;
		margin-bottom: 21px;
	}

	.inner_banner img {
		width: 100%;
	}

	.material_search {
		width: 100%;
		height: 70px;
		background-color: #f6f7f9;
		border-radius: 10px;
		border: solid 1px #eeeeee;
		box-sizing: border-box;
		padding-right: 160px;
		position: relative;
		margin-bottom: 12px;
	}

	.material_search input {
		background: transparent;
		width: 100%;
		height: 100%;
		padding: 0 24px;
		box-sizing: border-box;
		font-size: 16px;
	}

	.material_search input::placeholder {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
	}

	.material_search_button {
		position: absolute;
		top: 2px;
		right: 2px;
		width: 150px;
		height: 64px;
		background-image: linear-gradient(0deg, #5957ff 0%, #acaaff 100%);
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.material_class {
		height: 59px;
		line-height: 59px;
		font-size: 16px;
		color: #333333;
		border-bottom: 1px solid #eeeeee;
	}

	.material_class>div {
		position: relative;
		margin-left: 56px;
		cursor: pointer;
	}

	.material_class>div:first-child {
		margin-left: 0;
	}

	.material_class>div:hover {
		color: #5957ff;
	}

	.material_class>div.active {
		background: url(../assets/images/navbg.png) no-repeat;
		background-size: 33px 33px;
		background-position: center 34px;
		color: #5957ff;
	}

	.material_class>div.active span {
		display: block;
		width: 20px;
		height: 6px;
		background-color: #5957ff;
		position: absolute;
		left: 50%;
		margin-left: -10px;
		bottom: -1px;
	}

	.material_class_list {
		border-bottom: 1px solid #eeeeee;
		transition: max-height 0.6s;
		max-height: 400px;
	}

	.material_class_list.min {
		max-height: 0;
		overflow: hidden;
	}

	.material_class_item {
		position: relative;
		margin-bottom: 17px;
	}

	.material_class_item:first-child {
		margin-top: 27px;
	}

	.class_list_title {
		position: absolute;
		top: 0;
		left: 0;
		height: 30px;
		line-height: 30px;
		color: #999;
	}

	.class_list {
		width: 100%;
		padding-left: 52px;
		padding-right: 100px;
		box-sizing: border-box;
	}

	.class_list.mini {
		height: 40px;
		overflow: hidden;
	}

	.class_list>div {
		height: 30px;
		line-height: 28px;
		font-size: 15px;
		text-align: center;
		color: #333;
		box-sizing: border-box;
		margin: 0 2px;
		margin-bottom: 10px;
		padding: 0 10px;
		border-radius: 10px;
		cursor: pointer;
	}

	.class_list>div:hover {
		color: #221eff;
	}

	.class_list>div.active {
		color: #221eff;
		background: #ebebff;
	}

	.class_list_unfold {
		position: absolute;
		height: 30px;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		/* color: #5957FF; */
		color: #999999;
		cursor: pointer;
	}

	.class_list_unfold:hover {
		color: #5957ff;
	}

	.class_list_unfold img {
		margin-left: 4px;
	}

	.sort_l {
		font-size: 14px;
		color: #333333;
		line-height: 54px;
	}

	.sort_l_box {
		position: relative;
	}

	.sort_l_s {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding-left: 6px;
	}

	.sort_l_s img {
		margin-left: 9px;
	}

	.sort_l_ul {
		width: 100%;
		position: absolute;
		top: 54px;
		left: 0;
		z-index: 99;
	}

	.sort_l_ul>div {
		text-align: center;
		background: #fff;
		cursor: default;
		transition: 0.3s;
	}

	.sort_l_ul>div:hover {
		background: #ebebff;
		color: #5957ff;
	}

	.sort_r {
		font-size: 14px;
		color: #333333;
		line-height: 54px;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: 0.3s;
	}

	.sort_r:hover {
		color: #5957ff;
	}

	.sort_r img {
		margin: 0 6px 0 8px;
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.sort_r.min img {
		transform: rotate(0);
	}

	.list {
		margin-bottom: 86px;
		min-height: 500px;
		position: relative;
	}

	.daily {
		margin: 34px -13.33px 45px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		background-color: #f6f6f6;
		z-index: 9;
		position: relative;
	}

	.daily_ul {
		margin: 0 13.33px;
		width: calc(100% / 4 - 26.66px);
	}

	.daily_li {
		width: 100%;
		margin-bottom: 26.66px;
		cursor: pointer;
		/* visibility: hidden; */
	}

	.visibles {
		visibility: visible;
	}

	.daily_li_box {
		width: 330px;
		/* height: 218px; */
		border-radius: 10px;
		overflow: hidden;
		position: relative;
	}

	.daily_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.daily_li_downLoad {
		position: absolute;
		top: 15px;
		left: 15px;
		width: 72px;
		height: 40px;
		background-color: #5957ff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.daily_li_downLoad span {
		width: 1px;
		height: 18px;
		background-color: #ffffff;
		opacity: 0.38;
		margin: 0 8px;
	}

	.daily_li_collect,
	.daily_li_shop {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 15px;
		right: 62px;
		z-index: 100;
		opacity: 0;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.daily_li_shop {
		right: 15px;
	}

	.daily_li_collect img,
	.daily_li_shop img {
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.daily_li_collect:hover img,
	.daily_li_shop:hover img {
		opacity: 0.8;
	}

	.daily_li:hover .daily_li_mask {
		opacity: 1;
	}

	.daily_li:hover .daily_li_downLoad {
		opacity: 1;
	}

	.daily_li:hover .daily_li_collect {
		opacity: 1;
	}

	.daily_li:hover .daily_li_shop {
		opacity: 1;
	}

	/* 专题 */
	.project {
		margin: 28px -13.33px 40px;
	}

	.project_li {
		width: 330px;
		height: 300px;
		margin: 0 13.33px 30px;
		padding-top: 15px;
		background: url(../assets/images/bg_tjzt2x.png) no-repeat;
		background-size: 311px auto;
		background-position: center top;
	}

	.project_li_box {
		position: relative;
		overflow: hidden;
		border-radius: 10px;
		width: 330px;
		height: 300px;
	}

	.project_li_box>img,
	.video_li_box>img,
	.daily_li_box>img {
		/* position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); */
		width: 100%;
		height: auto;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_hot {
		position: absolute;
		top: 14px;
		left: -10px;
		width: 62px;
		height: 40px;
		background-color: #df0003;
		border-radius: 10px;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 40px;
		text-align: center;
		letter-spacing: 0px;
		color: #ffffff;
		z-index: 101;
		transition: 0.5s;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.project_li:hover .project_li_hot {
		left: 15px;
		padding-left: 0px;
	}

	.project_li_buttom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 45px;
		text-align: left;
		padding: 0 16px;
		box-sizing: border-box;
		line-height: 45px;
		color: #fff;
		font-size: 15px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-webkit-border-radius: 0;
		border-radius: 0;
		background: rgba(0, 0, 0, 0.4);
		opacity: 1;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.project_li_collect {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 14px;
		right: 15px;
		z-index: 100;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		cursor: pointer;
	}

	.project_li_collect img {
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_collect:hover img {
		opacity: 0.8;
	}

	.project_li:hover .project_li_box>img,
	.video_li:hover .video_li_box>img,
	.daily_li:hover .daily_li_box>img {
		transform: scale(1.08);
	}

	.project_li:hover .project_li_buttom {
		opacity: 0;
	}

	.project_li:hover .project_li_mask,
	.project_li:hover .project_li_collect {
		opacity: 1;
	}

	/* 视频教程 */
	.video_list {
		margin: 28px -13.33px 45px;
	}

	.video_li {
		margin: 0 13.33px 30px;
	}

	.video_li_box {
		width: 330px;
		height: 218px;
		border-radius: 10px;
		position: relative;
		overflow: hidden;
	}

	.video_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.video_li_height {
		position: absolute;
		top: 15px;
		left: 15px;
		width: 72px;
		height: 40px;
		background-color: #5957ff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.video_li_height span {
		width: 1px;
		height: 18px;
		background-color: #ffffff;
		opacity: 0.38;
		margin: 0 8px;
	}

	.video_li_collect {
		position: absolute;
		width: 40px;
		height: 40px;
		top: -70px;
		right: 15px;
		z-index: 100;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}

	.video_li_collect img {
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.video_li_play {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 101;
		margin-left: -37.5px;
		margin-top: -37px;
		width: 74px;
		height: 75px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		opacity: 0;
	}

	.video_li_collect:hover img {
		opacity: 0.8;
	}

	.video_li:hover .project_li_buttom {
		opacity: 0;
	}

	.video_li:hover .video_li_mask {
		opacity: 1;
	}

	.video_li:hover .video_li_height {
		opacity: 1;
	}

	.video_li:hover .video_li_collect {
		top: 15px;
		transition-delay: 0.4s;
	}

	.video_li:hover .video_li_play {
		opacity: 1;
	}

	/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */
	.video_li .video_li_box {
		height: 198px;
	}

	.video_li .video_thumb {
		position: relative;
		height: 198px;
		transition: all 0.3s ease-in-out;
	}

	.video_li .video_thumb>img {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: auto;
	}

	.video_li:hover .video_thumb {
		transform: scale(1.08);
	}
</style>